import React from "react";
import "./Banner.scss";

const Banner = (props) => {
  return (
    <div
      className="bannerContainer"
      style={{
        backgroundImage: `url(https:${props.bannerData.bannerImage.file.url})`,
      }}
    >
      <hr></hr>
      <div className="bannerContainer-content container-flex">
        <div className="bannerContainer-content--header container-flex .container-flex--align-top padding-left-100 uppercase">
          <p>{props.bannerData.bannerTitle[0]}</p>
          <p>
            {`${props.bannerData.bannerTitle[1]} `}
            <span>{props.bannerData.bannerTitle[2]}.</span>
          </p>
        </div>
        <div className="bannerContainer-content--grayContainer container-flex margin-top-50 margin-bottom-50">
          <div className="bannerContainer-content--grayContainer-left"></div>
          <div className="bannerContainer-content--grayContainer-right"></div>
        </div>
        <div className="bannerContainer-content--description container-inner-w--per-34 container-flex ">
          <p>
            <strong>{props.bannerData.bannerDescription[0]}</strong> <br />{" "}
            <span>{props.bannerData.bannerDescription[1]}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
