import React from "react";
import "./contactUsComponent.scss";

const ContactUsComponent = (props) => {
  return (
    <div
      className="contact-us container-flex container-inner-w--per-100 container-flex--align-center container-flex--justify-center"
      style={{
        backgroundImage: `url(https:${props.contactUsData.contactUsImage.file.url})`,
      }}
    >
      <div className="contact-us-content container-flex container-flex--align-center container-flex--justify-center">
        <div className="contact-us-content--description">
          <p>
            <span>{props.contactUsData.contactUsDescription[0]}</span>
            <span>{props.contactUsData.contactUsDescription[1]}</span>
          </p>
        </div>
        <div className="contact-us-content--largerText">
          {props.contactUsData.contactUsLargeTitle}
        </div>
        <div className="contact-us-content--button">
          <a
            className="btn-primary"
            href={props.contactUsData.button.buttonLink.pageSlug}
          >
            {props.contactUsData.button.buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUsComponent;
