import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Grid, Pagination, Navigation } from "swiper";
import "../../styles/core/_button.scss";
import "./gallery.scss";

const GalleryProject = (props) => {
  
  const [currentState, setCurrentState] = useState(1);
  const [totalSlides, setTotalSlides] = useState(5);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setTotalSlides(
        window.innerWidth < 570
          ? props.projectGallery.imageGallery?.length ?? 0
          : props.projectGallery.imageGallery?.length - 1 ?? 0
      );
    }
  }, [props.projectGallery?.imageGallery?.length]);

  const onSlideChange = (swiper) => {
    setCurrentState(swiper.activeIndex + 1);
  };

  const twoColumnLayoutContent = {
    leftContent: (
      <>
        <div className="project__container-left ">
          <h1>{props.projectGallery.title}</h1>
          <p> {props.projectGallery.description}</p>
        </div>
      </>
    ),
    rightContent: (
      <>
        <div className="project__container-right ">
          <h3> {props.projectGallery.swipeText}</h3>
        </div>
      </>
    ),
  };
  return (
    <div className="project-gallery-section">
      <div className="info__container">
        <TwoColumnsLayout
          leftContent={twoColumnLayoutContent.leftContent}
          rightContent={twoColumnLayoutContent.rightContent}
        />
      </div>
      <div className="gallery-projects">
        <Swiper
          onSlideChange={onSlideChange}
          slidesPerView={1}
          breakpoints={{
            570: {
              slidesPerView: 2.7,
              spaceBetween: 10,
            },
          }}
          grid={{
            rows: 1,
          }}
          spaceBetween={25}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Grid, Pagination, Navigation]}
          className="mySwiper"
        >
          {props.projectGallery?.imageGallery?.map((project, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={`products/${project.projectProduct.productCategory.categorySlug}/${project.projectProduct.productSlug}`}>
                <div className="image__gallery--container">
                  <img
                    src={`https:${project.projectImage.file.url}`}
                    alt="Alulux Gallery"
                    loading="lazy"
                  />
                </div>
                </Link>
                <h3>
                  {
                    project.projectText
                  }{" / "}
                  <strong style={{ textTransform: "capitalize" }}>
                    {
                      project.projectNameOfTheWindow
                    }
                  </strong>
                </h3>
                <p>
                  {project.projectYear}
                </p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="current-slide">
        {currentState}
        <span>of </span> {props.projectGallery?.imageGallery?.length}
      </div>
      <div className="project-gallery-section__button btn">
        <Link to={props.projectGallery.galleryButton.buttonLink.pageSlug} className="btn-primary">
          {props.projectGallery.galleryButton.buttonText}
        </Link>
      </div>
    </div>
  );
};
export default GalleryProject;