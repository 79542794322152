import React from "react";
import { Link } from "gatsby";

import "./ProductSolutionsHomepage.scss";
const ProductSolutionsHomepage = (props) => {
  const categoryContainer =
    props.productSolutionsData.productSolutionsContainer.containerItems;
  return (
    <div className="product-solutions-homepage">
      <div className="text">
        <h2>{props.productSolutionsData.productSolutionHeader}</h2>
        <p>
          {
            props.productSolutionsData.productSolutionsDescription
              .productSolutionsDescription
          }
        </p>
      </div>
      <div className="products-grid">
        <div className="operating-system">
          <img
            src={`https:${categoryContainer[0].categoryTeaserImage.file.url}`}
            alt={categoryContainer[0].categoryTeaserImage.description}
            loading="lazy"
          />
          <div className="border-title">
            <div className="title">
              {categoryContainer[0].category.categoryInnerTitle} <span></span>
            </div>
          </div>
          <div className="overlay">
            <div className="overlay__title">
              {categoryContainer[0].category.categoryInnerTitle}
            </div>
            <div className="overlay__links container-flex container-flex--direction-column">
              {categoryContainer[0].category.categoryProducts.map((item, i) => (
                <Link
                  to={`/products/${categoryContainer[0].category.categorySlug}/${item.product.productSlug}`}
                  target="_blank"
                  key={i}
                >
                  <span>{item.product.productInnerTitle}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="lift-and-slide">
          <img
            src={`https:${categoryContainer[1].categoryTeaserImage.file.url}`}
            alt={categoryContainer[1].categoryTeaserImage.description}
            loading="lazy"
          />
          <div className="border-title">
            <div className="title">
              {categoryContainer[1].category.categoryInnerTitle} <span></span>
            </div>
          </div>
          <div className="overlay">
            <div className="overlay__title">
              {categoryContainer[1].category.categoryInnerTitle}
            </div>
            <div className="overlay__links container-flex container-flex--direction-column">
              {categoryContainer[1].category.categoryProducts.map((item, i) => (
                <Link
                  to={`/products/${categoryContainer[1].category.categorySlug}/${item.product.productSlug}`}
                  target="_blank"
                  key={i}
                >
                  <span>{item.product.productInnerTitle}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="bi-folding">
          <img
            src={`https:${categoryContainer[2].categoryTeaserImage.file.url}`}
            alt={categoryContainer[2].categoryTeaserImage.description}
            loading="lazy"
            />
          <div className="border-title">
            <div className="title">
              {categoryContainer[2].category.categoryInnerTitle} <span></span>
            </div>
          </div>
          <div className="overlay">
            <div className="overlay__title">
              {categoryContainer[2].category.categoryInnerTitle}
            </div>
            <div className="overlay__links container-flex container-flex--direction-column">
              {categoryContainer[2].category.categoryProducts.map((item, i) => (
                <Link
                  to={`/products/${categoryContainer[2].category.categorySlug}/${item.product.productSlug}`}
                  target="_blank"
                  key={i}
                >
                  <span>{item.product.productInnerTitle}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="roller-shutters">
          <img
            src={`https:${categoryContainer[3].categoryTeaserImage.file.url}`}
            alt={categoryContainer[3].categoryTeaserImage.description}
            loading="lazy"
          />
          <div className="border-title">
            <div className="title">
              {categoryContainer[3].category.categoryInnerTitle} <span></span>
            </div>
          </div>
          <div className="overlay">
            <div className="overlay__title">
              {categoryContainer[3].category.categoryInnerTitle}
            </div>
            <div className="overlay__links container-flex container-flex--direction-column">
              {categoryContainer[3].category.categoryProducts ? categoryContainer[3].category.categoryProducts.map((item, i) => (
                <Link
                  to={`/products/${categoryContainer[3].category.categorySlug}/${item.product.productSlug}`}
                  target="_blank"
                  key={i}
                >
                  <span>{item.product.productInnerTitle}</span>
                </Link>
              )) : <p className="product-coming-soon">Coming Soon...</p>}
            </div>
          </div>
        </div>
        <div className="curtain-wall">
          <img
            src={`https:${categoryContainer[4].categoryTeaserImage.file.url}`}
            alt={categoryContainer[4].categoryTeaserImage.description}
            loading="lazy"
          />
          <div className="border-title">
            <div className="title">
              {categoryContainer[4].category.categoryInnerTitle} <span></span>
            </div>
          </div>
          <div className="overlay">
            <div className="overlay__title">
              {categoryContainer[4].category.categoryInnerTitle}
            </div>
            <div className="overlay__links container-flex container-flex--direction-column">
              {categoryContainer[4].category.categoryProducts.map((item, i) => (
                <Link
                  to={`/products/${categoryContainer[4].category.categorySlug}/${item.product.productSlug}`}
                  target="_blank"
                  key={i}
                >
                  <span>{item.product.productInnerTitle}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="insect-screen">
          <img
            src={`https:${categoryContainer[5].categoryTeaserImage.file.url}`}
            alt={categoryContainer[5].categoryTeaserImage.description}
            loading="lazy"
          />
          <div className="border-title">
            <div className="title">
              {categoryContainer[5].category.categoryInnerTitle} <span></span>
            </div>
          </div>
          <div className="overlay">
            <div className="overlay__title">
              {categoryContainer[5].category.categoryInnerTitle}
            </div>
            <div className="overlay__links container-flex container-flex--direction-column">
              {categoryContainer[5].category.categoryProducts.map((item, i) => (
                <Link
                  to={`/products/${categoryContainer[5].category.categorySlug}/${item.product.productSlug}`}
                  target="_blank"
                  key={i}
                >
                  <span>{item.product.productInnerTitle}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className=" btn button container-flex container-flex--justify-center">
        <Link
          to={
            props.productSolutionsData.productSolutonsButton.buttonLink.pageSlug
          }
          aria-label={
            props.productSolutionsData.productSolutonsButton.buttonInnerTitle
          }
          className="btn-primary" target="_blank"
        >
            {props.productSolutionsData.productSolutonsButton.buttonText}
        </Link>
      </div>
    </div>
  );
};

export default ProductSolutionsHomepage;
