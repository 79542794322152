import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Container from "../Container/Container";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";
import "../../styles/core/_button.scss";

import "./AboutUs.scss";

export default function AboutUs(props) {
  const [fixed, setFixed] = useState(false);
  const paragraph = props.aboutUsData.parargraph;
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 400) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);
  const twoColumnLayoutContent = {
    leftContent: (
      <>
        <div className="hp-our-process-left container-inner-w--per-100">
          <div className="title-large-64 dark">
            <h1 className="title-about-us">{props.aboutUsData.aboutUsTitle}</h1>
          </div>
          <div className="content">
            <div className="about-text">
              <div>{props.aboutUsData.redText}</div>
              <div className="description">
                <p>{props.aboutUsData.aboutUsContent}</p>
              </div>
              <div className="description">
                <p>{props.aboutUsData.aboutUsContent1}</p>
              </div>
            </div>
            <div className="btn">
              <Link to="/contact/" className="btn-primary">
                GET A QOUTE
              </Link>
            </div>
          </div>
        </div>
      </>
    ),

    rightContent: (
      <>
        <div className={fixed ? "social-buttons fixed " : "social-buttons"}>
          <div />
        </div>
        <div className="about-us-mobile-title">
          <h1>{props.aboutUsData.aboutUsTitle}</h1>
        </div>

        <div className="hp-our-process-right container-inner-w--per-100 container-flex container-flex--justify-end">
          <div className="image">
            <img
              src={`https:${props.aboutUsData.aboutUsImage.file.url}`}
              alt="Our Process"
              loading="lazy"
            />
          </div>
        </div>
      </>
    ),
  };

  return (
    <Container className="about-homepage">
      <div>
        <TwoColumnsLayout
          leftContent={twoColumnLayoutContent.leftContent}
          rightContent={twoColumnLayoutContent.rightContent}
        />

        <br />
        <br />
        <div className="about-us-learn-more">
          {props.aboutUsData && (
            <>
              {[2, 3, 4].map((i) => (
                <div key={i}>
                  <h1>{props.aboutUsData[`aboutUsTitle${i}`]}</h1>
                  <p>
                    {/* column 3 */}
                    {i === 3 ? paragraph[i * 5 - 9] : null}&nbsp;
                    <span>{paragraph[i * 3 - 8]}&nbsp;</span>
                    {i === 3 ? paragraph[i * 3 - 1] : null}&nbsp;
                    <br />
                    <br />
                    <span>{paragraph[i * 4 - 3]}&nbsp;</span>
                    {/*  */}
                    {i === 3 ? paragraph[i * 4 - 2] : null}
                    {/* column 2 */}
                    {i === 4 ? paragraph[i * 1 - 1] : null}
                    <span>&nbsp;{paragraph[i * 2 - 4]}&nbsp;</span>
                    {i === 4 ? paragraph[i * 2 - 3] : null} <br />
                    <br />
                    {i === 4 ? paragraph[i * 2 - 8] : null}
                    <span>&nbsp;{paragraph[i * 1 - 3]}&nbsp;</span>
                    {i === 4 ? paragraph[i * 1 - 2] : null}
                    {/* column 1 */}
                    {i === 2 ? paragraph[i * 8 - 5] : null}&nbsp;
                    <span>{paragraph[i * 9 - 6]}&nbsp;</span>
                    {i === 2 ? paragraph[i * 7 - 1] : null}&nbsp;
                    <span>{paragraph[i * 8 - 2]}&nbsp;</span>
                    {i === 2 ? paragraph[i * 8 - 1] : null}
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
        <br />
        <br />
        <div className="learn-more-button btn">
          <Link to={`/about/`} className="btn-primary">
            learn more about us
          </Link>
        </div>
      </div>
    </Container>
  );
}
