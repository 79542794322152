import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Container from "../components/Container/Container";
import TwoColumnsLayout from "../components/TwoColumnsLayout/TwoColumnsLayout";
import Banner from "../components/Banner/Banner";
import AboutUs from "../components/aboutUs/AboutUs";
import GalleryProject from "../components/Gallery/Gallery";
import ContactUsComponent from "../components/ContactUsComponent/ContactUsComponent";
import SocialMedia from "../components/SocialMedia/SocialMedia";


// Style Imports
import "./index.scss";
import ProductSolutionsHomepage from "../components/ProductSolutions - Homepage/ProductSolutionsHomepage";

export default function RootIndex({ data }) {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 400 && window.scrollY <= 5100) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);

  //page content
  const twoColumnLayoutContent = {
    leftContent: (
      <>
        <div className="container-inner-w--per-90 margin-top-25"></div>
      </>
    ),
    rightContent: (
      <>
        <div className="container-inner-w--per-90 margin-top-25 index"></div>
      </>
    ),
  };
  return (
    <Layout
    pageTitle={"Home"}
    images = {data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.layoutMain[0].bannerImage.file.url}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    >
      <Banner
        bannerData={
          data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
            .layoutMain[0]
        }
      />
      <div className={fixed ? "social-buttons fixed " : "social-buttons"}>
        <SocialMedia
          socialMediaData={
            data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
              .layoutMain[1].buttonsContainer
          }
        />
      </div>

      <ProductSolutionsHomepage
        productSolutionsData={
          data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
            .layoutMain[5]
        }
      />

      <AboutUs
        aboutUsData={
          data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
            .layoutMain[4]
        }
      />
      <GalleryProject
        projectGallery={
          data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
            .layoutMain[3]
        }
      />
      <Container>
        <ContactUsComponent
          contactUsData={
            data.allContentfulWebPages.edges[0].node.pages[0].pageLayout
              .layoutMain[2]
          }
        />
        <TwoColumnsLayout
          leftContent={twoColumnLayoutContent.leftContent}
          rightContent={twoColumnLayoutContent.rightContent}
        />
      </Container>
    </Layout>
  );
}

export const query = graphql`
query MyQuery {
  allContentfulWebPages {
    edges {
      node {
        pages {
          pageLayout {
            navigation {
              navigationPages {
                pageTitle
                pageSlug
                pageLayout {
                  layoutMain {
                    ... on ContentfulContainer {
                      containerItems {
                        ... on ContentfulCategoryTeaser {
                          category {
                            categoryInnerTitle
                            categorySlug
                            categoryProducts {
                              product {
                                productInnerTitle
                                productSlug
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              navigationImages {
                file {
                  url
                }
                description
              }
              navigationMediaIcons {
                socialMediaIcon {
                  file {
                    url
                  }
                  description
                }
                socialMediaTitle
                socialMediaLink
              }
            }
            layoutMain {
              ... on ContentfulFullWidthBanner {
                bannerImage {
                  file {
                    url
                  }
                }
                bannerTitle
                bannerDescription
              }
              ... on ContentfulButtonsContainer {
                buttonsContainer {
                  socialMediaLink
                  socialMediaTitle
                }
              }
              ... on ContentfulAboutUsComponent {
                aboutUsTitle
                redText
                aboutUsImage {
                  file {
                    url
                  }
                }
                aboutUsTitle2
                aboutUsTitle3
                aboutUsTitle4
                parargraph
                aboutUsContent
                aboutUsContent1
              }
              ... on ContentfulHomepageGallery {
                title
                swipeText
                description
                galleryButton {
                  buttonText
                  buttonLink {
                    ... on ContentfulPage {
                      id
                      pageSlug
                    }
                  }
                }
                imageGallery {
                  projectImage {
                    file {
                      url
                    }
                  }
                  projectName
                  projectNameOfTheWindow
                  projectText
                  projectYear
                  projectProduct {
                    productCategory {
                      categorySlug
                    }
                    productSlug
                  }
                }
              }
              ... on ContentfulProductSolutionsHomepage {
                productSolutionHeader
                productSolutionsDescription {
                  productSolutionsDescription
                }
                productSolutonsButton {
                  buttonText
                  buttonInnerTitle
                  buttonLink {
                    ... on ContentfulPage {
                      pageSlug
                    }
                  }
                }
                productSolutionsContainer {
                  containerItems {
                    ... on ContentfulCategoryTeaser {
                      categoryTeaserName
                      category {
                        categoryInnerTitle
                        categorySlug
                        categoryProducts {
                          product {
                            productInnerTitle
                            productSlug
                          }
                        }
                      }
                      categoryTeaserImage {
                        file {
                          url
                        }
                        description
                      }
                    }
                  }
                }
              }
              ... on ContentfulContactUsComponent {
                contactUsDescription
                contactUsLargeTitle
                button {
                  buttonText
                  buttonLink {
                    ... on ContentfulPage {
                      pageSlug
                    }
                  }
                }
                contactUsImage {
                  file {
                    url
                  }
                }
              }
            }
            footer {
              subscribeText
              subscribeDescription
              footerButtonText
              blockTitleFollowUs
              socialMediaLogosFooter {
                socialMediaIcon {
                  file {
                    url
                  }
                }
                socialMediaLink
              }
              followUsDescription
              blockTitleAddress
              blockInfoAddress
              blockTitleCallUs
              blockInfoCallUs
              blockTitleWriteUs
              blockInfoWriteUs
              blockTitleExplore
              footerPages {
                pageTitle
                pageSlug
              }
              copyrightText
              googleMapsAddress {
                googleMapsAddress
              }
            }
          }
        }
      }
    }
  }
}
`;
